/**
 * A dummy class to trigger the compiler to copy the font files
 */
/**
 * @asset(iconfont/material/MaterialIcons-Regular.eot)
 * @asset(iconfont/material/MaterialIcons-Regular.ttf)
 * @asset(iconfont/material/MaterialIcons-Regular.woff)
 * @asset(iconfont/material/MaterialIcons-Regular.woff2)
 */
qx.Class.define("iconfont.material.Load", {});
