
qx.Class.define('cv.Version', {
  type: 'static',

  statics: {
    REV: '6fa34664bd75bab30f040569290d5aec3bb4350b',
    BRANCH: 'HEAD',
    VERSION: '0.12.2',
    LIBRARY_VERSION: 9,
    DATE: '2023-03-28T17:14:56.854Z',
    TAGS: { 
      RUNTIME: 'docker'
    }
  }
});    
